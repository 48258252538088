import React from "react"
import {StickyTable, Row, Cell} from 'react-sticky-table'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { Button } from "reactstrap"
// import { ReactTypeformEmbed } from "react-typeform-embed"
// import { Link } from "gatsby"

import useTranslations from "../useTranslations"
import { Popup } from "react-typeform-embed"
import { UtmContext } from "../../utils/utmContext"
import localeContext from "../../utils/localeContext"
import { config } from "../../utils/const";


const PricingTable = ({landingLink, filter, PricingTableDataNew}) => {

  const { locale } = React.useContext(localeContext);
  const { global } = useTranslations();
  const { utm } = React.useContext(UtmContext);


  const PricingTableData = [
        {
          header: "Ads created",
          benefits: {
            tool: 0,
            brillea: 1,
            freelancer: 0,
            agency:1
          }
        },
        {
          header: "Persuasive copywriting",
          benefits: {
            tool: 0,
            brillea: 1,
            freelancer: 0,
            agency:1
          }
        },
        {
          header: "Ad build & setup",
          benefits: {
            tool: 0,
            brillea: 1,
            freelancer: 1,
            agency:1
          }
        },
        {
          header: "Ad " + global.optimisation,
          benefits: {
            tool: 1,
            brillea: 1,
            freelancer: 1,
            agency:1
          }
        },
        {
          header: "Spend " + global.optimisation,
          benefits: {
            tool: 0,
            brillea: 1,
            freelancer: 1,
            agency:1
          }
        },
        {
          header: "Campaign management",
          benefits: {
            tool: 0,
            brillea: 1,
            freelancer: 1,
            agency:1
          }
        },
        {
          header: "New ads tested",
          benefits: {
            tool: 1,
            brillea: 1,
            freelancer: 0,
            agency:1
          }
        },
        {
          header: "Growth strategy",
          benefits: {
            tool: 1,
            brillea: 1,
            freelancer: 0,
            agency:0
          }
        },
        {
          header: "Real time reporting",
          benefits: {
            tool: 1,
            brillea: 1,
            freelancer: 0,
            agency:0
          }
        },
        {
          header: "Marketing expert",
          benefits: {
            tool: 0,
            brillea: 1,
            freelancer: 1,
            agency:1
          }
        },
        {
          header: "Real time chats & queries",
          benefits: {
            tool: 0,
            brillea: 1,
            freelancer: 1,
            agency:1
          }
        },
        {
          header: "Face to face meetings",
          benefits: {
            tool: 0,
            brillea: 0,
            freelancer: 0,
            agency:1
          }
        },
    ];

    let count = 0
    if(filter?.tool) { count = count + 1}
    if(filter?.brillea) { count = count + 1}
    if(filter?.freelancer) { count = count + 1}
    if(filter?.agency) { count = count + 1}

    const PricingData = (PricingTableDataNew ? PricingTableDataNew : PricingTableData)

    return(
        <>
        <div className={"PricingTable_wrapper PricingTable_wrapper_" + count}>
        <StickyTable stickyHeaderCount={1} leftStickyColumnCount={1}>
            <Row>
                <Cell className="PricingTable_wrapper__header row_header">Monthly cost</Cell>
                {(filter?.tool &&
                <Cell className="PricingTable_wrapper__header">
                    <span className="header_label">Marketing tool</span>
                    <span className="header_value">{global.marketing_tool}</span>
                </Cell>
                )}
                {(filter?.brillea &&
                <Cell className="PricingTable_wrapper__header brillea_highlight">
                    <span className="header_label">brillea</span>
                    <span className="header_value">{global.brillea_1000}</span>
                </Cell>
                )}
                {(filter?.freelancer &&
                <Cell className="PricingTable_wrapper__header">
                    <span className="header_label">Freelancer</span>
                    <span className="header_value">{global.freelancer}</span>
                </Cell>
                )}
                {(filter?.agency &&
                <Cell className="PricingTable_wrapper__header">
                    <span className="header_label">Agency</span>
                    <span className="header_value">{global.agency}</span>
                </Cell>
                )}
            </Row>
            {    
                PricingData.map(({ header, benefits }, key) => (
                    <Row key={header} className={( key & 1 ) ? "odd" : "even"}>
                        <Cell className="header"><p>{header}</p></Cell>
                        {(filter?.tool ? (
                            <Cell className={"icon_wrapper"}> {benefits?.tool ? <FontAwesomeIcon icon={faCheck}/> : null} </Cell> 
                        ): null)}
                        {(filter?.brillea ? (
                            <Cell className={"brillea_highlight icon_wrapper"}> {benefits?.brillea ? <FontAwesomeIcon icon={faCheck}/> : null} </Cell> 
                        ): null)}
                        {(filter?.freelancer ? (
                            <Cell className={"icon_wrapper"}> {benefits?.freelancer ? <FontAwesomeIcon icon={faCheck}/> : null} </Cell> 
                        ):null)}
                        {(filter?.agency ? (
                            <Cell className={"icon_wrapper"}> {benefits?.agency ? <FontAwesomeIcon icon={faCheck}/> : null} </Cell> 
                        ):null)}
                    </Row>
                ))
            }
            <Row className="PricingTable_wrapper__footer">
                <Cell></Cell>
                {(filter?.tool ? (<Cell></Cell>) : null)}
                {(filter?.brillea ? (<Cell className="brillea_highlight">
                  <Popup 
                      id={(locale === 'en-US' ? config?.typeform?.getstarted?.US : config?.typeform?.getstarted?.UK)}
                      size={95} 
                      hidden={{ utm }}
                      tooltip={"Get started with brillea"}
                      onReady={() => { window.dataLayer.push({ event: "openForm", CTA: "PricingTable", }) }}
                      onSubmit={() => { window.dataLayer.push({ event: "getStartedFormComplete", CTA: "PricingTable", }) }}
                  >
                  <Button color="primary" className="btn-purple" >Get started</Button>
                  </Popup>
                  </Cell>) : null)}
                {(filter?.freelancer ? (<Cell></Cell>) : null)}
                {(filter?.agency ? (<Cell></Cell>) : null)}
            </Row>
        </StickyTable>
    </div>

    </>
    )
}


export default PricingTable;