import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import localeContext from "../utils/localeContext";
// import { useTranslation } from "react-i18next"

function useTranslations() {
  // const { i18n } = useTranslation()
  // Grab the locale (passed through context) from the Context Provider
  // const language = i18n.language;
  const { locale } = React.useContext(localeContext);
  // Set a default locale
  const location = (!locale ? 'en-GB' : locale);
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query); 

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations
    };
  });

  // Only return translations for the current locale
  if(simplified){
    const { translations } = simplified.filter(lang => lang.name === location )[0];
    return translations;
  } else {
    return null;
  }
}

export default useTranslations;

const query = graphql`
  query useTranslations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            global { 
              country,
              brillea_1000,
              currency,
              marketing_tool,
              freelancer,
              agency,
              optimise,
              optimisation,
              Optimisation,
              optimising,
              product {
                start {
                  label,
                  value
                },
                grow {
                  label,
                  value
                },
                scale {
                  label,
                  value
                }
              }
            }
            home {
              channel {
                sub_title
              }
              mission {
                mission_3
              }
            }
            agencyLP{
              channel {
                sub_title
              }
            }
            HowWeCanHelp {
              cleverStrategies {
                body
              }
            }
            experts {
              joinBrillea {
                body
              }
              goodFit {
                country
              }
              expertCards{
                earn {
                  subTitle
                }
              }
            }
            hello
            subline
            backToHome
          }
        }
      }
    }
  }
`;
