import React from "react"

import SEO from "../../components/SEO"
import { Link } from "gatsby"

// import { ReactTypeformEmbed } from "react-typeform-embed"
import { Container, Row, Col, Button } from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faHeart,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"
import { Count } from "../../components/Home/Count";
import brillea_cloud from "../../../static/images/2/home/brillea_cloud.svg";

// import FooterEmailSigngup from "../components/Footer/FooterEmailSigngup"

import home_header_url from "../../../static/images/2/home/header.svg"
import home_header_bk from "../../../static/images/2/home/header_bk.svg"


import AOS from 'aos';

import PricingTable from "../../components/PricingTable"
import localeContext from "../../utils/localeContext"
import useTranslations from "../../components/useTranslations"


import generate_sales from "../../../static/images/2/home/generate_sales.svg"
import generate_sales_us from "../../../static/images/2/home/generate_sales_en-US.svg"
import illustration_signups from "../../../static/images/2/home/illustration_signups_2.svg"
import illustration_downloads from "../../../static/images/2/home/illustration_downloads.svg"
import ContactPageComp from "../../components/ContactPage"
import { UtmContext } from "../../utils/utmContext"

const AgencyPage = () => {
  
  const { locale } = React.useContext(localeContext);
  const { utm } = React.useContext(UtmContext);
  const { global } = useTranslations();

    React.useEffect(() => {
        AOS.init();
    },[]);

    let landingLink = '#contact';


    const PricingTableData = [
      {
        header: "Ad creation & copywriting",
        benefits: {
          tool: 0,
          brillea: 1,
          freelancer: 0,
          agency:1
        }
      },
      {
        header: "Testing & " + global.optimising,
        benefits: {
          tool: 0,
          brillea: 1,
          freelancer: 1,
          agency:1
        }
      },
      {
        header: "Facebook, Instagram & Google expert",
        benefits: {
          tool: 0,
          brillea: 1,
          freelancer: 1,
          agency:1
        }
      },
      {
        header: "Account manager",
        benefits: {
          tool: 0,
          brillea: 1,
          freelancer: 1,
          agency:1
        }
      },
      {
        header: "Proven growth strategy",
        benefits: {
          tool: 1,
          brillea: 1,
          freelancer: 0,
          agency:0
        }
      },
      {
        header: "Reporting",
        benefits: {
          tool: 1,
          brillea: 1,
          freelancer: 0,
          agency:1
        }
      },
      {
        header: "Face to face meetings",
        benefits: {
          tool: 1,
          brillea: 0,
          freelancer: 0,
          agency:1
        }
      },
  ];

    return (
      <>
        <SEO
          title="brillea - helping purpose-driven startups launch & grow"
          description="Acquire new customers from Facebook, Instagram and Google for £699 a month. Let brillea create, manage and optimise your ads"
        />

        <div className={"section section_header section_home"}>
          <div className="section_header__bk_image">
            <img src={home_header_bk} alt="brillea smarter digital marketing" />
          </div>
          <Container>
            <Row>
              <Col
                className={"homeHeaderContent mb-5"}
                md={{ size: 7 }}
                sm={{ size: 12 }}
              >
                <h1 className="mb-3">Get all the advantages of a digital marketing agency</h1>
                <h3 className="mb-4">Without the fluff or cost</h3>
                <Button
                  color="secondary"
                  tag={Link} to={landingLink}
                  size="lg"
                >
                  Get started
                </Button>
              </Col>
              <Col
                className="homeHeaderImage_wrapper"
                md={{ size: 5 }}
                sm={{ size: 12 }}
              >
                <img src={home_header_url} alt="brillea agency alternative" />
              </Col>
            </Row>
          </Container>
        </div>


        <div className={"section section_channels section_medium text-center"}>
          <Container>
            <Row>
              <Col className="text-center">
                <h2>Need more customers from Facebook, Instagram and Google?</h2>
                <p className="intro_heading mt-3 ">
                  The perfect alternative to a digital marketing, Facebook or Instagram advertising agency
                </p>
              </Col>
            </Row>
            <Row className="mt-4 ">
              <Col md={{ size: 4 }} sm={{ size: 12 }}>
                <img
                  src={(locale === 'en-US' ? generate_sales_us : generate_sales)}
                  alt="generate sales with marketing"
                  className="channel_img"
                />
                <br />
                <h3 className="mt-2">Generate sales</h3>
              </Col>
              <Col md={{ size: 4 }} sm={{ size: 12 }}>
                <img
                  src={illustration_signups}
                  alt="Get signups with marketing"
                  className="channel_img"
                />
                <br />
                <h3 className="mt-2">Get signups</h3>
              </Col>
              <Col md={{ size: 4 }} sm={{ size: 12 }}>
                <img
                  src={illustration_downloads}
                  alt="Increase app downloads with digital marketing"
                  className="channel_img"
                />
                <br />
                <h3 className="mt-2">Increase app downloads</h3>
              </Col>
            </Row>
          </Container>
        </div>



        <div className={"section section_large bk-purple section_solution"}>
          <div className="solution_image">
            <img src={brillea_cloud} alt="brillea marekting solution"/>
            <div className="solution_icons">
              <div data-aos={"zoom-in-up"} data-aos-delay="1500">
                <div className="solution_icons__round solution_icons__like">
                  <FontAwesomeIcon icon={faThumbsUp}/>
                </div>
              </div>
              <div data-aos={"zoom-in-up"} data-aos-delay="1200">
                <div className="solution_icons__round solution_icons__like_2">
                  <FontAwesomeIcon icon={faThumbsUp}/>
                </div>
              </div>
              <div data-aos={"zoom-in-up"} data-aos-delay="900">
                <div className="solution_icons__round solution_icons__heart">
                  <FontAwesomeIcon icon={faHeart}/>
                </div>
              </div>

              <div data-aos={"zoom-in-up"} data-aos-delay="600">
                <div className="solution_icons__tag solution_icons__tag_heart">
                  <FontAwesomeIcon icon={faHeart}/>
                  <div className="number_count">
                    <Count/>
                  </div>
                </div>
              </div>
              <div className="solution_icons__tag solution_icons__tag_like" data-aos={"zoom-in-up"} data-aos-delay="0">
                  <FontAwesomeIcon icon={faThumbsUp}/>
                </div>
            </div>
          </div>
          <Container>
            <Row>
              <Col md={{size:8, offset:4}}>
                <h2 className="h1 color_white mb-4">
                Digital marketing agencies are expensive and old fashioned. We know, we used to be one!
                </h2>
                <h3 className="color_white mb-4">
                So we took the best bits of an agency and combined it with some clever tech to create brillea. 
                </h3>
                <h3 className="color_white mb-4">
                Our mission is to provide deserving businesses with marketing that actually works and they can afford.
                </h3>
              </Col>
            </Row>
          </Container>
        </div>



        <div
          className={"section_pricing_table section section_medium bk-grey"}
          id="pricing_table"
        >
          <Container>
            <Row>
              <Col md={{size:5, offset:1}} sm={{size:12}}>
                <h2 className="h1 pt-4 mb-4">brillea gives <br/>you everything needed to win</h2>
              </Col>
              <Col md={{size:6}} sm={{size:12}}>
               
              <ul className="tick_list">
                  <li className="mb-4"><h4><FontAwesomeIcon icon={faCheck}/> A {global.country} based Facebook / Instagram / Google pro to do the hard work</h4></li>
                  <li className="mb-4"><h4><FontAwesomeIcon icon={faCheck}/> A proven process &amp; strategy that gets great results and actually can make a real difference to your business</h4></li>
                  <li className="mb-4"><h4><FontAwesomeIcon icon={faCheck}/> Access to your marketing platform to make life simple and easy</h4></li>
                </ul>
                <h3 className="color-purple-light mb-1">All for a fixed {global.brillea_1000} a month subscription</h3>
                <p className="mb-0"><small>No minimum term, cancel any time</small></p>
              </Col>
            </Row>
          </Container>
        </div>


        <div
          className={"section_pricing_table section section_medium mt-3"}
          id="pricing_table"
        >
          <Container>
            <Row>
              <Col>
                <h2 className="text-center">
                  Get all this for {global.brillea_1000} a month. Why pay more?
                </h2>
                <p className="intro text-center mb-5">
                  brillea provides everything you need to get new customers from Facebook, Instagram &amp; Google Search
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <PricingTable landingLink={landingLink} filter={{tool:false,brillea:true,freelancer:false,agency:true}} PricingTableDataNew={PricingTableData}/>
              </Col>
            </Row>
          </Container>
        </div>



        <div className={"section section_medium bk-grey section_agitation"}>
          <Container>
          <Row>
              <Col>
                <h2 className="text-center mb-5">
                There are hundreds of agencies but there's only one brillea!
                </h2>
              </Col>
            </Row>
            <Row>
              {/* <Col md={{ size: 4, offset:1 }}>

                <h3>You've taken the leap and doing what you love. We salute you! You don't want to stop now but your business needs more customers to grow. It's simple, you need help with your marketing.</h3>
                
              </Col> */}
              {/* <Col  md={{ size: 5 }}> */}
              <Col md={{ size: 10, offset:1 }}>
                <ul className="tick_list tick_list_50">
                  <li className="mb-4"><h4 className="tick_list_large"><FontAwesomeIcon icon={faCheck}/> Unrivalled value, <br/>no agency comes close</h4></li>
                  <li className="mb-4"><h4 className="tick_list_large"><FontAwesomeIcon icon={faCheck}/> Get better results following our proven process</h4></li>
                  <li className="mb-4"><h4 className="tick_list_large"><FontAwesomeIcon icon={faCheck}/> The flexibility and ability to cancel at any time</h4></li>
                  <li className="mb-4"><h4 className="tick_list_large"><FontAwesomeIcon icon={faCheck}/> Easier &amp; less time consuming than managing an agency. Let brillea guide you to growth</h4></li>
                  <li className="mb-4"><h4 className="tick_list_large"><FontAwesomeIcon icon={faCheck}/> Get full transparency with none of the agency fluff or hot air</h4></li>
                  <li className="mb-4"><h4 className="tick_list_large"><FontAwesomeIcon icon={faCheck}/> Get how marketing should be done in the 21st Century</h4></li>

                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <ContactPageComp title="Get in touch and lets explore how brillea can help your business get more customers" utm={utm} filter={{form:true, chat:false, signUp:false}}/>


      </>
    )
}
export default AgencyPage
